import React, { Component } from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'

// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import FormControl from '@material-ui/core/FormControl';
// import FormGroup from '@material-ui/core/FormGroup';
// import FormLabel from '@material-ui/core/FormLabel';
// import TextField from '@material-ui/core/TextField';
// import RadioGroup from '@material-ui/core/RadioGroup';
// import Radio from '@material-ui/core/Radio';
// import Checkbox from '@material-ui/core/Checkbox';

class ThankYouPage extends Component {
  render() {
    return (
      <Layout>
        <SEO title="Thanks!" keywords={[`gatsby`, `application`, `react`]} />

        <section>
            <h2>Thanks!</h2>
            <div className="container">
                <p>I will get back to you shortly.</p>
            </div>
        </section>
      </Layout>
    )
  }
}

export default ThankYouPage
